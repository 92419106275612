export const CUSTOM_EVENT_TYPE = {
  REMOVE_LIST: "remove_list"
}

interface Detail {
  value: any;
}

export class FacilityId implements Detail {
  constructor(value: number) {
    this.value = value
  }
  value: number
}

/**
 * カスタムイベントのファクトリークラス
 */
export class CustomEventFactory {
  /**
     * コンストラクタ
     * @param type CUSTOM_EVENT_TYPEの値
     */
  constructor(type: string) {
    this.typeArgs = type
  }

  protected typeArgs: string

  private mkEventInitDict = (detail: Detail): CustomEventInit => {
    return {
      detail: detail.value
    }
  };

  /**
     * CustomEvent 生成メソッド
     * @param detail
     */
  generateCustomEvent = <T>(detail: Detail): CustomEvent<T> | undefined => {
    if (this.typeArgs == CUSTOM_EVENT_TYPE.REMOVE_LIST && detail instanceof FacilityId) {
      return new CustomEvent<T>(this.typeArgs, this.mkEventInitDict(detail))
    } else {
      return undefined
    }
  }
}