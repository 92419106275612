import $ from 'jquery';
import {
  DocumentRequestVisitReservationRequest,
  DocumentRequestVisitReservationRequestList,
} from '@/common/ts/DocumentRequestVisitReservationRequestList';

import {
  CUSTOM_EVENT_TYPE,
  FacilityId,
  CustomEventFactory,
} from '@/sp/ts/_custom_event';

/*!
表示パターン
■見学予約・資料請求リストに施設が存在しない。
■見学予約・資料請求リストに施設が存在しない。
施設詳細ページにいる。
■見学予約・資料請求リストに施設が存在する。
施設詳細ページにいる。
表示している施設が見学予約・施設詳細リストにいない。
■見学予約・資料請求リストに施設が存在する。
施設詳細ページにいる。
表示している施設が見学予約・施設詳細リストにいる。

メッセージ
l-header__reserve--dialog ⇒ 見学予約を追加しました。
l-header__request--dialog ⇒ 資料請求を追加しました。
l-header__reserve--requestError,l-header__request--requestError ⇒ リストに追加できるのは10件までとなります。
*/

$(window).on('load', async () => {
  const documentRequestVisitReservationRequestList = new DocumentRequestVisitReservationRequestList();
  await documentRequestVisitReservationRequestList.init();
  const locationPath = location.pathname;

  // 見学予約・資料請求リスト投入施設数カウンタ更新
  count();
  // 見学予約・資料請求リスト作成
  switchListContents(documentRequestVisitReservationRequestList, locationPath);
});

// 見学予約・資料請求リストボタン
$('.js-modal--stepButton').on('click', async () => {
  const documentRequestVisitReservationRequestList = new DocumentRequestVisitReservationRequestList();
  await documentRequestVisitReservationRequestList.init();
  const locationPath = location.pathname;
  // 見学予約・資料請求リスト作成
  switchListContents(documentRequestVisitReservationRequestList, locationPath);
});

// 見学予約・資料請求リストチェックボックス、更新
$('.js-table--homeList').on(
  'click',
  'td.js-table--homeList__cbArea',
  async function() {
    const documentRequestVisitReservationRequestList = new DocumentRequestVisitReservationRequestList();
    await documentRequestVisitReservationRequestList.init();

    const cbx = $(this).children('input');
    const cbxId = cbx.attr('id')?.split('_') as string[];
    const kind = cbxId[0];
    const facilityId = parseInt(cbxId[1]);
    const name = $(this)
      .nextAll('.js-table--homeList__name')
      .text();

    if (kind == 'doc') {
      const isRequestDocument = $(cbx).prop('checked');
      const isRequestVisit = $(cbx)
        .parent()
        .prev()
        .children('input')
        .prop('checked');
      const value: DocumentRequestVisitReservationRequest = {
        facilityId: facilityId,
        name: name,
        isRequestDocument: isRequestDocument,
        isRequestVisit: isRequestVisit,
        lastUpdatedAt: Math.floor(new Date().getTime() / 1000),
      };
      documentRequestVisitReservationRequestList.update(value);
      //検索結果一覧 のチェックボックスのチェック外し
      removeSearchedListCheckBox(facilityId, 'doc', isRequestDocument);
    } else {
      const isRequestDocument = $(cbx)
        .parent()
        .next()
        .children('input')
        .prop('checked');
      const isRequestVisit = $(cbx).prop('checked');
      const value: DocumentRequestVisitReservationRequest = {
        facilityId: facilityId,
        name: name,
        isRequestDocument: isRequestDocument,
        isRequestVisit: isRequestVisit,
        lastUpdatedAt: Math.floor(new Date().getTime() / 1000),
      };
      documentRequestVisitReservationRequestList.update(value);
      //検索結果一覧 のチェックボックスのチェック外し
      removeSearchedListCheckBox(facilityId, 'visit', isRequestVisit);
    }
  }
);

// 見学予約・資料請求リスト削除ボタン
$('.js-table--homeList').on(
  'click',
  'td.js-table--homeList__deleteButton',
  function() {
    const name = $(this)
      .prevAll('.js-table--homeList__name')
      .text();
    const facilityId = $(this).attr('id') as string;
    $('#deleteModalFacilityName').text(name);
    $('#deleteModalFacilityId').text(facilityId);
    $('.js-alert').fadeIn();
    return false;
  }
);

// 削除アラート「はい」選択
$('.js-alert__itemButtonYes').on('click', function() {
  //削除アラート「はい」タップ後イベント発生
  $.when(
    $('.js-alert').fadeOut() //削除アラート非表示
  ).done(async function() {
    // リスト削除

    const facilityId = parseInt($('#deleteModalFacilityId').text());

    // 検索画面チェックボックスのチェック外し
    removeSearchedListCheckBox(facilityId, 'all', false);

    const documentRequestVisitReservationRequestList = new DocumentRequestVisitReservationRequestList();
    await documentRequestVisitReservationRequestList.init();
    const locationPath = location.pathname;
    await documentRequestVisitReservationRequestList.remove(facilityId);

    switchListContents(
      documentRequestVisitReservationRequestList,
      locationPath
    );
    count();
    // カスタムイベントの発火（facility.tsで のDom制御用）
    const facilityIdForCustomEvent = new FacilityId(facilityId);
    const factory = new CustomEventFactory(CUSTOM_EVENT_TYPE.REMOVE_LIST);
    const removeEvent = factory.generateCustomEvent<
      typeof facilityIdForCustomEvent.value
    >(facilityIdForCustomEvent);

    const customEventModule = document.querySelector(
      '.js-alert__itemButtonYes.js-customEventModule'
    ) as HTMLElement;
    if (removeEvent) {
      customEventModule?.dispatchEvent(removeEvent);
    }
  });
  return false;
});

//見学予約/資料請求のリストからのstepへの遷移
$('.js-header__listSubmit').on('click', async () => {
  const documentRequestVisitReservationRequestList = new DocumentRequestVisitReservationRequestList();
  await documentRequestVisitReservationRequestList.init();
  if (
    documentRequestVisitReservationRequestList.value.filter(
      (x: DocumentRequestVisitReservationRequest) => x.isRequestVisit === true
    ).length >= 6
  ) {
    alert('見学予約は5施設までです。');
  } else {
    location.href = '/step/snr_step/step_init.html';
  }
});

export const jsModalStepButton = '.js-modal--stepButton';

const facilityPathRegex = RegExp('^/facility/[0-9]{5}/$');

function switchListContents(
  documentRequestVisitReservationRequestList: DocumentRequestVisitReservationRequestList,
  locationPath: string
) {
  $('.js-homelist__noneDetail').hide();
  $('.js-homelist__none').hide();
  $('#facilityListAddButton').hide();
  $('.js-homelist__add').hide();

  // 見学予約・資料請求リスト表示切り替え
  if (documentRequestVisitReservationRequestList.isEmpty()) {
    // 見学予約・資料請求リストに施設が存在しない
    $('.js-listSubmit').removeClass('c-submitButton--l');
    $('.js-listSubmit').addClass('c-addButton--l');
    if (isCurrentFacility(locationPath)) {
      // 施設詳細ページを表示中
      $('.js-homelist__noneDetail').show();
    } else {
      $('.js-homelist__none').show();
    }
  } else {
    $('#facilityList')
      .children('tbody')
      .empty();
    appendFacilityListHeader();
    documentRequestVisitReservationRequestList.value.forEach(
      appendFacilityList
    );

    $('.js-listSubmit').removeClass('c-addButton--l');
    $('.js-listSubmit').addClass('c-submitButton--l');

    // 見学予約・資料請求リストに施設が存在する
    if (isCurrentFacility(locationPath)) {
      // 施設詳細ページ表示中
      const paths = locationPath.split('/');
      const facilityId = parseInt(paths[paths.length - 2]);

      if (!documentRequestVisitReservationRequestList.existsFacility(facilityId)) {
        // 見学予約・資料請求リストに施設が存在しない
        $('#facilityListAddButton').show();
      }
    }
    $('.js-homelist__add').show();
  }
}

export function removeMessage(deleteClass: string): number {
  return setTimeout(function() {
    return $('.js-modal--stepButton').removeClass(deleteClass);
  }, 2000);
}

export async function count() {
  // 見学予約・資料請求リスト投入施設数カウンタ更新
  const documentRequestVisitReservationRequestList = new DocumentRequestVisitReservationRequestList();
  await documentRequestVisitReservationRequestList.init();
  $('#facilityCounter').text(
    documentRequestVisitReservationRequestList.value.length
  );
  if (documentRequestVisitReservationRequestList.isEmpty()) {
    $('.js-listSubmit').removeClass('c-submitButton--l');
    $('.js-listSubmit').addClass('c-addButton--l');
  } else {
    $('.js-listSubmit').removeClass('c-addButton--l');
    $('.js-listSubmit').addClass('c-submitButton--l');
  }
}

function isCurrentFacility(path: string): boolean {
  return facilityPathRegex.test(path);
}

function appendFacilityListHeader(): void {
  $('#facilityList').children('tbody').append(`
  <tr>
  <th class="p-table--homeList__cbArea">見学予約</th>
  <th class="p-table--homeList__cbArea">資料請求</th>
  <th class="p-table--homeList__nameArea">施設名</th>
  <th class="p-table--homeList__delete">削除</th>
  </tr>
  `);
}

function appendFacilityList(
  value: DocumentRequestVisitReservationRequest
): void {
  $('#facilityList').children('tbody').append(`
  <tr>
  <td class="p-table--homeList__cbArea js-table--homeList__cbArea">
  <input type="checkbox" id="visit_${value.facilityId}" class="c-checkBox" name="visit_${value.facilityId}">
  <label for="visit_${value.facilityId}" class="c-cBLabel p-inputCb--t"></label>
  </td>
  <td class="p-table--homeList__cbArea js-table--homeList__cbArea">
  <input type="checkbox" id="doc_${value.facilityId}" class="c-checkBox" name="doc_${value.facilityId}">
  <label for="doc_${value.facilityId}" class="c-cBLabel p-inputCb--t"></label>
  </td>
  <td class="js-table--homeList__name">${value.name}</td>
  <td class="p-table--homeList__deleteButton js-table--homeList__deleteButton" id="${value.facilityId}"></td>
  </tr>
  `);

  if (value.isRequestDocument) {
    $(`#doc_${value.facilityId}`).prop('checked', true);
  }

  if (value.isRequestVisit) {
    $(`#visit_${value.facilityId}`).prop('checked', true);
  }
}

//検索一覧でのチェックボックス処理
function removeSearchedListCheckBox(
  facilityId: number,
  kind: 'doc' | 'visit' | 'all',
  isChecked: boolean
): void {
  const reserve = <HTMLInputElement>(
    document.getElementById(`reserve_${facilityId}`)
  );
  const material = <HTMLInputElement>(
    document.getElementById(`material_${facilityId}`)
  );

  if (reserve && kind === 'visit') {
    reserve.checked = isChecked;
  }

  if (material && kind === 'doc') {
    material.checked = isChecked;
  }

  if (material && reserve && kind === 'all') {
    reserve.checked = false;
    material.checked = false;
  }
}

$(".l-header__tel").on("click", function () {
  $(this).addClass("hover")
})
$(window).scroll(function(){
  const scroll = $(window).scrollTop() || 0;
  if(scroll > 1) {
    $(".l-header__tel").removeClass("hover")
  }
})
