import axios from 'axios';

export class DocumentRequestVisitReservationRequestList {
  constructor() {
    this.value = [];
  }

  init = async () => {
    await this.getList().then(list => {
      const jsonList = JSON.parse(list);
      const vList = this.jsonToArray(jsonList);

      const storagePlainList = vList.map(i => {
        const jsonValue = i as JSONValueTypeObject;

        const facilityId = jsonValue.facilityId as number;
        const name = jsonValue.name as string;
        const isRequestDocument = jsonValue.isRequestDocument as boolean;
        const isRequestVisit = jsonValue.isRequestVisit as boolean;
        const lastUpdatedAt = jsonValue.lastUpdatedAt as number;

        const value: DocumentRequestVisitReservationRequest = {
          facilityId: facilityId,
          name: name,
          isRequestDocument: isRequestDocument,
          isRequestVisit: isRequestVisit,
          lastUpdatedAt: lastUpdatedAt
        };

        return value;
      });

      const expiredDate = new Date();
      expiredDate.setMonth(expiredDate.getMonth() - 2);
      const expiredAt = Math.floor(expiredDate.getTime() / 1000);

      storagePlainList
        .filter(i => i.lastUpdatedAt <= expiredAt)
        .forEach(i => {
          this.remove(i.facilityId);
        });

      this.value = storagePlainList.filter(i => i.lastUpdatedAt > expiredAt);
    });
  };

  value: DocumentRequestVisitReservationRequest[];

  isEmpty = (): boolean => this.value.length == 0;

  existsFacility = (request: number): boolean => {
    return this.value.filter(e => e.facilityId == request).length > 0;
  };

  exists = (request: DocumentRequestVisitReservationRequest): boolean => {
    return this.value.filter(e => e.facilityId == request.facilityId
      && e.isRequestDocument == request.isRequestDocument
      && e.isRequestVisit == request.isRequestVisit).length > 0;
  };

  private getList = async (): Promise<string> => {
    return await axios.get('/api/order_list').then(response => response.data);
  };

  private postList = async (list: DocumentRequestVisitReservationRequest[]) => {
    await axios
      .post('/api/order_list', JSON.parse(JSON.stringify(list)))
      .then(response => {
        if (response.status == 200) {
          this.value = list;
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  private jsonToArray = (jsonList: any): unknown[] => {
    if (jsonList) {
      return Array.from(jsonList);
    } else {
      return [];
    }
  };

  get = async (
    facilityId: number
  ): Promise<DocumentRequestVisitReservationRequest> => {
    const list = JSON.parse(JSON.stringify(this.value)) as DocumentRequestVisitReservationRequest[];
    return list.filter(v => v.facilityId == facilityId)[0];
  };

  add = async (
    request: DocumentRequestVisitReservationRequest
  ): Promise<AddResult> => {
    if (this.existsFacility(request.facilityId)) {
      return 'Duplication';
    }
    if (this.value.length < 10) {
      const list = JSON.parse(
        JSON.stringify(this.value)
      ) as DocumentRequestVisitReservationRequest[];
      list.push(request);
      await this.postList(list);

      if (list.length == this.value.length) {
        return 'Success';
      } else {
        return 'Failed';
      }
    } else {
      return 'Limit';
    }
  };

  update = async (
    request: DocumentRequestVisitReservationRequest
  ): Promise<void> => {
    const list = JSON.parse(
      JSON.stringify(this.value)
    ) as DocumentRequestVisitReservationRequest[];
    const updateIndex = list
      .map(e => e.facilityId)
      .indexOf(request.facilityId, 0);
    list[updateIndex] = request;
    await this.postList(list);
  };

  upsert = async (
    request: DocumentRequestVisitReservationRequest
  ): Promise<AddResult> => {
    if (this.existsFacility(request.facilityId)) {
      this.update(request);
      return Promise.resolve('Success');
    } else {
      return await this.add(request);
    }
  };

  remove = async (facilityId: number): Promise<AddResult> => {
    const list = JSON.parse(
      JSON.stringify(this.value)
    ) as DocumentRequestVisitReservationRequest[];
    const removeIndex = list.map(e => e.facilityId).indexOf(facilityId, 0);
    if (removeIndex > -1) {
      list.splice(removeIndex, 1);
      await this.postList(list);
    }
    return Promise.resolve('Removed');
  };

  removeAll = async (): Promise<void> => {
    await this.postList([]);
  };
}

export interface DocumentRequestVisitReservationRequest {
  facilityId: number;
  name: string;
  isRequestDocument: boolean;
  isRequestVisit: boolean;
  lastUpdatedAt: number;
}

export type AddResult =
  | 'Success'
  | 'Limit'
  | 'Duplication'
  | 'Removed'
  | 'Failed';

type JSONValueType = string | number | boolean;

interface JSONValueTypeObject {
  [key: string]: JSONValueType;
}
